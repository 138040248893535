//*******************************************************
// global
//*******************************************************

// import './library/global.js';

//*******************************************************
// styles
//*******************************************************

import '../scss/main.scss';

if (!window.UARK) {
  // check for UARK settings object. If present, use global anchorJS setup
  import('./anchorjs.js');
}

$(function() {

  $(document).on('click', function(evt) {
    if (!$(evt.target).hasClass('dropdown-trigger')) {
      $('#mainnav .in').collapse('hide');
    }
  });
});

$.expr[':'].classStartsWith = function(el, i, selector) {
  /* eslint-disable no-magic-numbers */
  let re = new RegExp('\\b' + selector[3]);
  return re.test(el.className);
};
$(function() {
  $(':classStartsWith("prepend-fa")', '#main-container, #mainnav').each(function() {
    let classes = $(this).attr('class')
      .split(' ');
    let icoClasses = [ 'fa' ];
    let found = false;
    $.each(classes, function(idx, value) {
      if (0 === value.indexOf('prepend-')) {
        found = true;
        icoClasses.push(value.replace('prepend-', ''));
      }
    });
    if (found) {
      $(this).prepend(' ')
        .prepend($('<i/>').addClass(icoClasses.join(' ')));
    }
  });

});

$(function() {
  if (window.location.search.substr(1) !== 'unbranded') {
    return;
  }

  $('a').click(function() {
    let href = $(this).attr('href');
    if (href.substr(0, 1) === '/') {
      href = href.split('#');

      if (href[0].indexOf('?') === -1) {
        href[0] = href[0] + '?unbranded';
      } else {
        href[0] = href[0] + '&unbranded';
      }
      $(this).attr('href', href.join('#'));
    } else {
      $(this).attr('target', '_top');
    }
    return true;
  });
});
$(function() {
  if (top.location.href.indexOf('omniupdate') !== -1) {
    $('<style>#header-bg,#opener,.navbar.navbar-default {display:block;}</style>').appendTo('head');
  }
});
